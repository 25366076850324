import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../common/scss/common.scss";
import "./CustomersApp.scss";

import {
    getCustomersData,
    selectSearchTerm,
    setSearchTerm
} from "./store/customersSlice";

import { customersConfigLang } from './CustomersConfig'

import PageTitle from '../common/components/PageTitle'
import ActionButton from "../common/components/ActionButton";

import CustomersTableHeader from "./CustomersHeader";
import CustomersTable from "./CustomersTable";

const CustomersApp = () => {
    const dispatch = useDispatch();

    const searchTerm = useSelector(selectSearchTerm);

    const onNewCustomer = () => {
        let newCustomerUrl = "/index.php?page=3-1_Ausleiher_anlegen&neu=1";
        if (searchTerm.startsWith("institution=")) {
            const institutionId = searchTerm.split("=")[1];
            newCustomerUrl += `&institution_id=${institutionId}`;
        }

        window.location.href = newCustomerUrl;
    }

    // if the URL contains a filter_institution parameter, set the search term to institution=filter_institution
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const filterInstitution = urlParams.get('filter_institution');

        if (filterInstitution) {
            dispatch(setSearchTerm(`institution=${filterInstitution}`));
        }
    }, []);
    
    useEffect(() => {
        dispatch(getCustomersData());
    }, [searchTerm]);

    return (
        <div id="customers_app_content" className="col-one-full">
            <PageTitle title={customersConfigLang.page_title} />

            <div className="flex flex__col col-one-half">
                <ActionButton onClick={onNewCustomer} text={customersConfigLang.add_customer} />
            </div>

            <div className="flex flex__col">
                <div className="data-table-block dash-block-container-sp data-table-block-extended ">
                    <CustomersTableHeader />

                    <div className="table_wrapper">
                        <CustomersTable />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomersApp;
